var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "route-list route-details" },
    [
      _c(
        "b-container",
        [
          _c(
            "b-row",
            { staticClass: "p-2" },
            [
              _c(
                "b-col",
                { staticClass: "background--white", attrs: { cols: "10" } },
                [
                  _c("div", { staticClass: "color--basic p-3" }, [
                    _c("p", [
                      _c("span", [_vm._v("Identificator: ")]),
                      _vm._v(_vm._s(_vm.model.id)),
                    ]),
                    _c("p", [
                      _c("span", [_vm._v("Stops count: ")]),
                      _vm._v(_vm._s(_vm.model.stopsCount)),
                    ]),
                  ]),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass: "pointer background--white",
                  attrs: { cols: "2" },
                },
                [
                  _c("div", { staticClass: "color--basic p-3 text-center" }, [
                    _c(
                      "a",
                      {
                        class: !_vm.$store.getters.isOnline ? "disabled" : "",
                        on: {
                          click: () => {
                            if (_vm.$store.getters.isOnline) {
                              _vm.downloadPdf()
                            }
                          },
                        },
                      },
                      [
                        _c("b-icon", {
                          staticStyle: { width: "30px", height: "30px" },
                          attrs: {
                            icon: "file-earmark-medical",
                            variant: "primary",
                          },
                        }),
                        _c("br"),
                        _vm._v(" Export to PDF "),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm.imageBase64
            ? _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "12" } }, [
                    _c("img", {
                      staticStyle: {
                        overflow: "auto",
                        "overflow-clip-margin": "unset",
                        width: "100%",
                        height: "100%",
                      },
                      attrs: {
                        src: `data:image/jpg;base64,` + _vm.imageBase64,
                      },
                    }),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            { staticClass: "p-2" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "color--white w-100",
                      attrs: {
                        disabled: !_vm.$store.getters.isOnline,
                        type: "button",
                        variant: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.goToNextRoute()
                        },
                      },
                    },
                    [_vm._v(" Go to next stop ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "p-2" },
            [
              _c(
                "b-col",
                { staticClass: "text-center", attrs: { cols: "12" } },
                [
                  _c("div", { staticClass: "color--basic p-3" }, [
                    _c("p", { staticClass: "font--bold" }, [_vm._v("Stops:")]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._l(_vm.model.stops, function (stop) {
            return _c(
              "b-row",
              { key: stop.id, staticClass: "p-2 pr-0" },
              [
                _c(
                  "b-col",
                  { class: _vm.stopStyle(stop), attrs: { cols: "10" } },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "w-100 h-100 flex-center",
                        attrs: {
                          disabled: !_vm.$store.getters.isOnline,
                          event: _vm.$store.getters.isOnline ? "click" : "",
                          to: {
                            name: "route-item-details",
                            params: {
                              routeId: _vm.$route.params.routeId,
                              itemId: stop.id,
                            },
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "color--basic p-3" }, [
                          _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(stop.address1) +
                                " " +
                                _vm._s(stop.address2) +
                                " " +
                                _vm._s(stop.city) +
                                " " +
                                _vm._s(stop.postcode) +
                                " "
                            ),
                          ]),
                          _c("p", [
                            _c("span", [_vm._v("Number of trees: ")]),
                            _vm._v(_vm._s(stop.numberOfTrees)),
                          ]),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  {
                    staticClass: "flex-center",
                    class: _vm.stopStyle(stop),
                    attrs: { cols: "2" },
                  },
                  [
                    _c(
                      "router-link",
                      {
                        class: "w-100 h-100 flex-center",
                        attrs: {
                          disabled: !_vm.$store.getters.isOnline,
                          event: _vm.$store.getters.isOnline ? "click" : "",
                          to: {
                            name: "route-item-details",
                            params: {
                              routeId: _vm.$route.params.routeId,
                              itemId: stop.id,
                            },
                          },
                        },
                      },
                      [
                        stop.status === _vm.stopStatuses.readyToCollect
                          ? _c("b-icon", {
                              staticStyle: { width: "35px", height: "35px" },
                              attrs: {
                                icon: "box-arrow-in-right",
                                variant: "primary",
                              },
                            })
                          : _vm._e(),
                        stop.status === _vm.stopStatuses.collected
                          ? _c("b-icon", {
                              staticStyle: { width: "35px", height: "35px" },
                              attrs: {
                                icon: "check2-square",
                                variant: "secondary",
                              },
                            })
                          : _vm._e(),
                        stop.status === _vm.stopStatuses.requiresAttention
                          ? _c("b-icon", {
                              staticStyle: { width: "35px", height: "35px" },
                              attrs: {
                                icon: "exclamation-square",
                                variant: "danger",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _c(
            "b-row",
            { staticClass: "logoutButton color--white mt-3" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "color--white w-100",
                      attrs: {
                        disabled: !_vm.$store.getters.isOnline,
                        type: "button",
                        variant: "danger",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.logout()
                        },
                      },
                    },
                    [_vm._v(" Logout ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }