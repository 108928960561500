<template>
  <div class="route-list route-details">
    <b-container>
      <b-row class="p-2">
        <b-col cols="10" class="background--white">
          <div class="color--basic p-3">
            <p><span>Identificator: </span>{{ model.id }}</p>
            <p><span>Stops count: </span>{{ model.stopsCount }}</p>
          </div>
        </b-col>
        <b-col cols="2" class="pointer background--white">
          <div class="color--basic p-3 text-center">
            <a
              :class="!$store.getters.isOnline ? 'disabled' : ''"
              @click="
                () => {
                  if ($store.getters.isOnline) {
                    downloadPdf();
                  }
                }
              "
            >
              <b-icon
                icon="file-earmark-medical"
                variant="primary"
                style="width: 30px; height: 30px"
              ></b-icon
              ><br />
              Export to PDF
            </a>
          </div>
        </b-col>
      </b-row>

      <b-row v-if="imageBase64">
        <b-col cols="12">
          <img style="overflow: auto; overflow-clip-margin: unset; width: 100%; height: 100%;" 
            :src="`data:image/jpg;base64,` + imageBase64" />
        </b-col>
      </b-row>

      <b-row class="p-2">
        <b-col cols="12">
          <b-button
            :disabled="!$store.getters.isOnline"
            type="button"
            variant="primary"
            class="color--white w-100"
            @click="goToNextRoute()"
          >
            Go to next stop
          </b-button>
        </b-col>
      </b-row>
      <b-row class="p-2">
        <b-col cols="12" class="text-center">
          <div class="color--basic p-3">
            <p class="font--bold">Stops:</p>
          </div>
        </b-col>
      </b-row>
      <b-row class="p-2 pr-0" v-for="stop in model.stops" :key="stop.id">
        <b-col cols="10" :class="stopStyle(stop)">
          <router-link
            :disabled="!$store.getters.isOnline"
            :event="$store.getters.isOnline ? 'click' : ''"
            class="w-100 h-100 flex-center"
            :to="{
              name: 'route-item-details',
              params: { routeId: $route.params.routeId, itemId: stop.id },
            }"
          >
            <div class="color--basic p-3">
              <p>
                {{ stop.address1 }} {{ stop.address2 }} {{ stop.city }}
                {{ stop.postcode }}
              </p>
              <p><span>Number of trees: </span>{{ stop.numberOfTrees }}</p>
            </div>
          </router-link>
        </b-col>
        <b-col cols="2" class="flex-center" :class="stopStyle(stop)">
          <router-link
            :disabled="!$store.getters.isOnline"
            :event="$store.getters.isOnline ? 'click' : ''"
            :class="'w-100 h-100 flex-center'"
            :to="{
              name: 'route-item-details',
              params: { routeId: $route.params.routeId, itemId: stop.id },
            }"
          >
            <b-icon
              v-if="stop.status === stopStatuses.readyToCollect"
              icon="box-arrow-in-right"
              variant="primary"
              style="width: 35px; height: 35px"
            ></b-icon>
            <b-icon
              v-if="stop.status === stopStatuses.collected"
              icon="check2-square"
              variant="secondary"
              style="width: 35px; height: 35px"
            ></b-icon>
            <b-icon
              v-if="stop.status === stopStatuses.requiresAttention"
              icon="exclamation-square"
              variant="danger"
              style="width: 35px; height: 35px"
            ></b-icon>
          </router-link>
        </b-col>
      </b-row>
      <b-row class="logoutButton color--white mt-3">
        <b-col cols="12">
          <b-button
            :disabled="!$store.getters.isOnline"
            type="button"
            variant="danger"
            class="color--white w-100"
            @click="logout()"
          >
            Logout
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import store from "../stores";
import { AUTH_LOGOUT, UNLOCK_ROUTE } from "../stores/modules/constants";
import { stopStatus } from "../domain/enums";

export default {
  name: "RouteDetails",
  data() {
    return {
      model: Object,
      imageBase64: String,
      canEnterToRouteList: true,
      isCompleted: false,
      stopStatuses: null,
    };
  },
  mounted() {
    this.stopStatuses = stopStatus;

    this.getDetails();
    this.getRoutesMap();
    this.canEnterToRouteList = !sessionStorage.getItem("user-route");
  },
  methods: {
    getDetails() {
      let self = this;
      this.$setBusy(true);
      let routeUrl =
        process.env.VUE_APP_ROUTE_URL + "/" + this.$route.params.routeId;
      axios
        .get(routeUrl)
        .then(function (response) {
          self.model = response.data;
          self.$setBusy(false);
        })
        .catch(function () {});
    },
    getRoutesMap(){
      let self = this;
      this.$setBusy(true);
      let downloadMapUrl = process.env.VUE_APP_ROUTE_URL + "/" + this.$route.params.routeId + "/downloadMap";
      axios
        .get(downloadMapUrl)
        .then(function (response) {
          self.imageBase64 = response.data;
          self.$setBusy(false);
        })
        .catch(function () {});
    },
    ping() {
      let pingUrl = process.env.VUE_APP_BASE_URL + "/ping";

      axios
        .get(pingUrl)
        .then(function () {
          alert("ok");
        })
        .catch(function () {
          alert("not ok");
        });
    },
    downloadPdf() {
      let self = this;

      this.$setBusy(true);

      let downloadPdfUrl =
        process.env.VUE_APP_ROUTE_URL +
        "/" +
        this.$route.params.routeId +
        "/downloadPdf";

      axios
        .get(downloadPdfUrl, { responseType: "arraybuffer" })
        .then(function (response) {
          self.$setBusy(false);

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", self.$route.params.routeId + ".pdf");
          document.body.appendChild(link);
          link.click();
        });
    },
    triggerRouteLock() {
      let self = this;
      self.hasError = false;
      this.$setBusy(true);
      let routeUrl =
        process.env.VUE_APP_ROUTE_URL +
        "/" +
        this.$route.params.routeId +
        "/lock";
      axios({
        method: "PUT",
        url: routeUrl,
      })
        .then(function (response) {
          if (response.data.hasError) {
            self.hasError = true;
          } else {
            sessionStorage.setItem("user-route", response.data.reservedRoute);
            self.canEnterToRouteList = !self.canEnterToRouteList;
          }
          self.$setBusy(false);
        })
        .catch(function () {
          self.hasError = true;
          self.$setBusy(false);
        });
    },
    triggerRouteUnlock() {
      let self = this;
      self.hasError = false;
      this.$setBusy(true);
      let routeUrl =
        process.env.VUE_APP_ROUTE_URL +
        "/" +
        this.$route.params.routeId +
        "/unlock";
      axios({
        method: "PUT",
        url: routeUrl,
      })
        .then(function (response) {
          if (response.data.hasError) {
            self.hasError = true;
          } else {
            sessionStorage.removeItem("user-route");
            self.canEnterToRouteList = !self.canEnterToRouteList;
            store.commit(UNLOCK_ROUTE);
          }
          self.$setBusy(false);
        })
        .catch(function () {
          self.hasError = true;
          self.$setBusy(false);
        });
    },
    goToNextRoute() {
      let nextRoute = this.model.stops.filter((item) => {
        return item.status === stopStatus.readyToCollect;
      });
      if (nextRoute.length) {
        this.$router.push({
          name: "route-item-details",
          params: {
            routeId: this.$route.params.routeId,
            itemId: nextRoute[0].id,
          },
        });
      }
    },
    stopStyle(stop) {
      var isDisabledCss = "";
      if (!this.$store.getters.isOnline) {
        isDisabledCss = " disabled";
      }

      if (stop.status === stopStatus.readyToCollect)
        return "background--white" + isDisabledCss;

      if (stop.status === stopStatus.requiresAttention)
        return "background--amber" + isDisabledCss;

      if (stop.status === stopStatus.collected)
        return "background--pepper" + isDisabledCss;
    },
    logout() {
      this.$store.dispatch(AUTH_LOGOUT).then(() => {
        this.$router.push("/login");
      });
    },
  },
};
</script>